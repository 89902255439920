<template>
	<div class="joinus">
		<div class="constrFormIlustration">
			<img class="ill" :src="path(compdata.image)" :alt="path(compdata.image)">
		</div>
		<div class="constrFormCaption">{{compdata.title}}</div>
		<div class="constrInputCaption">{{compdata.subtitle}}</div>
		<a :href="$store.getters.formLnk" target="_blank" class="donationBtn"><span class="ic-hand-1"></span> {{compdata.btn_name}}</a>
	</div>
</template>

<script>
export default {
	name: 'JoinUsForm',
	props: ['compdata']
}
</script>

<style lang="scss">
.joinus{
	max-width: 700px;
	margin: 60px 0;
	margin-left: auto !important;
	margin-right: auto !important;
	.ill{
		max-width: 100%;
	}
}
.constrFormIlustration img{
	margin: 20px auto;
	display: block;
}
</style>