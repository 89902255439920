<template>
	<div class="layoutWrp">
		<MobHeaderMenu
			@menuswitch="$emit('menuswitch')"
			style="position: static"
		></MobHeaderMenu>
		<transition name="fadefast" mode="out-in">
			<div v-if="!loaded" class="loadingFiller" key="ld">
				<div class="lds-dual-ring"></div>
			</div>
			<div v-if="loaded" :key="'pagecontent'">
				<section class="peoplesStoryPage">
					<div class="twoCol">
						<div class="l">
							<div class="posSticky">
								<div class="topHd">
									<router-link
										:to="{
											name: 'home',
											params: {
												locale: $i18n.locale == 'uk' ? null : $i18n.locale,
											},
										}"
										class="backBtn"
									>
										<span class="ic-arrow-long-left"></span>
										{{ $t("menu.mainPage") }}
									</router-link>
								</div>
								<!-- TODO Перевести -->
								<h1>{{$store.getters.donorsPageTitle}}</h1>
								<div class="tagsField">
									<router-link v-for="(item, index) in articles" :key="index" :to="{path: item.url}" class="tagItem">{{item.name}}</router-link>
								</div>
							</div>
						</div>
						<div class="r">
							<div class="storyPeoples">
								<div v-for="(item, index) in donors" :key="index" class="itemWrp">
									<router-link :to="{name: 'donor', params:{slug: item.model.slug, locale: $i18n.locale == 'uk' ? null : $i18n.locale}}" class="item">
										<div class="imgWrap">
											<img
												class="pImg"
												:src="path(item.model.image)"
												:alt="path(item.model.image)"
											/>
											<div class="sub">
												<span class="bp">
													<img :src="require('@/assets/img/b.svg')" alt="" />
												</span>
												<span class="donations">
													<img
														:src="require('@/assets/img/plumb.svg')"
														alt=""
													/>
													<span>{{item.model.donations}}</span>
												</span>
											</div>
										</div>
										<div>
											<p class="name">{{item.translate.title}}</p>
											<p class="city">{{item.translate.city}}</p>
										</div>
									</router-link>
								</div>
							</div>
							<join-us :compdata="constructor.join_us"></join-us>
						</div>
					</div>
				</section>
			</div>
		</transition>
	</div>
</template>

<script>
import MobHeaderMenu from "@/components/common/MobHeaderMenu.vue";
import JoinUsForm from "@/components/constructor/JoinUsForm.vue";
export default {
	components: {
		MobHeaderMenu,
		"join-us": JoinUsForm,
	},
	data() {
		return {
			loaded: false,
			donors: null,
			articles: null,
			constructor: null,
		}
	},
	created () {
		this.axios.post('/api/donors/all', {
			"lang": this.$i18n.locale
		}).then( response => {
			this.donors = response.data.data.models;
			this.articles = response.data.data.menu;
			this.constructor = response.data.data.constructor;
			this.loaded = true;
		})
	},
};
</script>

<style lang="scss">
.layoutWrp {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}
.peoplesStoryPage {
	flex-grow: 1;
	background: #f8f8f8;
	padding: 24px 48px 64px;
	@media (max-width: 765px) {
		padding: 24px 24px 64px;
	}
	@media (max-width: 357px) {
		padding: 24px 16px 64px;
	}
	.twoCol {
		display: flex;
		@media (max-width: 1365px) {
			flex-wrap: wrap;
		}
		.l {
			width: 550px;
			padding-right: 108px;
			box-sizing: border-box;
			flex-shrink: 0;
			@media (max-width: 1365px) {
				width: 100%;
				padding-right: 0px;
			}
			.posSticky{
				position: sticky;
				top: 30px;
			}
		}
		.r {
			flex-grow: 1;
			@media (max-width: 1365px) {
				width: 100%;
			}
		}
	}
	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 46px;
		line-height: 100%;
		color: #aeaeb2;
		margin: 20px 0;
		@media (max-width: 1119px) {
			font-size: 36px;
		}
		@media (max-width: 375px) {
			font-size: 30px;
		}
	}
	.tagsField {
		.tagItem {
			height: 38px;
		}
	}
	.storyPeoples {
		padding-top: 48px;
		display: flex;
		flex-wrap: wrap;
		@media (max-width: 768px) {
			padding-top: 16px;
		}
		.itemWrp {
			width: 33.33%;
			padding: 0 5px;
			box-sizing: border-box;
			margin-bottom: 32px;
			@media (max-width: 1365px) {
				width: 25%;
			}
			@media (max-width: 1023px) {
				width: 33.33%;
			}
			@media (max-width: 575px) {
				width: 50%;
			}
		}
		.item {
			display: block;
			text-decoration: none;
			margin-right: 8px;
			margin-left: 8px;
			@media (max-width: 1600px) {
				margin-right: 0px;
				margin-left: 0px;
			}
			.imgWrap {
				overflow: hidden;
				position: relative;
				.pImg {
					width: 100%;
					// height: 611px;
					object-fit: cover;
					display: block;
					transition: 0.3s ease;
				}
				.sub {
					position: absolute;
					bottom: 8px;
					right: 16px;
					display: flex;
					& > * + * {
						margin-left: 7px;
					}
					.bp {
						display: block;
						width: 46px;
						height: 46px;
						border-radius: 50px;
						background-color: #e10b17;
						display: flex;
						justify-content: center;
						align-items: center;
						@media (max-width: 1365px) {
							width: 34px;
							height: 34px;
						}
						img {
							@media (max-width: 1365px) {
								height: 19px;
							}
						}
					}
					.donations {
						height: 46px;
						display: flex;
						align-items: center;
						background-color: #fff;
						border-radius: 50px;
						border-top-right-radius: 0;
						padding: 0 16px;
						text-decoration: none;
						@media (max-width: 1365px) {
							height: 34px;
							padding: 0 11px;
						}
						img {
							margin-right: 12px;
							@media (max-width: 1365px) {
								margin-right: 9px;
							}
						}
						span {
							font-style: normal;
							font-weight: 600;
							font-size: 13px;
							line-height: 17px;
							color: #000000;
						}
					}
				}
			}
			&:hover {
				.imgWrap .pImg {
					transform: scale(1.1);
				}
			}
			.name {
				font-style: normal;
				font-weight: 600;
				font-size: 15px;
				line-height: 19px;
				color: #000000;
				margin: 10px 0 0;
			}
			.city {
				font-style: normal;
				font-weight: 600;
				font-size: 13px;
				line-height: 17px;
				color: #000000;
				margin: 10px 0 0;
			}
		}
		.itemMore {
			// height: 611px;
			margin: 0 8px;
			text-decoration: none;
			position: relative;
			display: block;
			@media (max-width: 1600px) {
				margin-right: 0px;
				margin-left: 0px;
			}
			.bg {
				width: 100%;
				margin-top: 0;
			}
			.w {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
			}
			.t {
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 23px;
				text-align: center;
				color: #ffffff;
				max-width: 230px;
				margin: 0 10px;
				@media (max-width: 1600px) {
					font-size: 18px;
				}
				@media (max-width: 768px) {
					font-size: 14px;
				}
			}
			.sticker {
				margin-top: 32px;
				width: 188px;
				max-width: calc(100% - 70px);
			}
		}
	}
	.FormWrp {
		max-width: 672px;
		margin: 48px auto 0;
		.constrFormIlustration {
			img {
				max-width: 100%;
			}
		}
	}
}
</style>